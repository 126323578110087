<template>
  <div class="flex justify-center h-full w-full">
    <div class="w-full sm:w-max-3xl">
      <div v-if="products" class="w-full flex flex-col gap-4">
        <div class="flex justify-between w-full">
          <span class="text-2xl font-bold">Product List</span>
          <div class="flex gap-x-4">
            <vs-checkbox v-model="verified"> Verified </vs-checkbox>

            <vs-checkbox v-model="ready"> Ready </vs-checkbox>

            <vs-checkbox v-model="deleted"> Deleted </vs-checkbox>
          </div>
        </div>

        <ProductTable :products="products" />
      </div>
    </div>
  </div>
</template>

<script>
import { getAllProducts } from "@/services/ApiService";
import ProductTable from "@/views/admin/product/ProductTable";

export default {
  name: "Products",
  components: { ProductTable },
  data() {
    return {
      products: undefined,
      verified: false,
      ready: true,
      deleted: false,
    };
  },
  methods: {
    async getAllProducts() {
      const loading = this.$vs.loading({
        type: "points",
        background: "rgba(0, 0, 0, 0.6)",
        color: "primary",
      });

      this.products = await getAllProducts(
        0,
        this.verified,
        this.ready,
        this.deleted
      );
      loading.close();
    },
  },
  watch: {
    async verified() {
      await this.getAllProducts();
    },
    async ready() {
      await this.getAllProducts();
    },
    async deleted() {
      await this.getAllProducts();
    },
  },
  async created() {
    await this.getAllProducts();
  },
};
</script>

<style scoped>

</style>
