<template>
  <div class="sm:w-max-3xl w-full">
    <vs-table class="w-full relative" ref="table" v-model="selected">
      <template #thead>
        <vs-tr>
          <vs-th class="!w-2">
            <i class="bx bx-check" />
          </vs-th>
          <vs-th class="!w-1/3"> Id </vs-th>
          <vs-th class="!w-1/6"> User </vs-th>
          <vs-th class="!w-1/7"> Name </vs-th>
          <vs-th> Game </vs-th>
          <vs-th> Created At</vs-th>
        </vs-tr>
      </template>
      <template #tbody v-if="products">
        <vs-tr
          :key="i"
          v-for="(product, i) in products.docs"
          :data="product"
          class="cursor-pointer"
          :is-selected="selected === product"
        >
          <vs-td>
            <i v-if="checkActive(product)" class="bx bx-x"></i>
            <i v-else class="bx bx-check"></i>
          </vs-td>
          <vs-td>
            {{ product._id }}
          </vs-td>
          <vs-td>{{ product.user.username }}</vs-td>
          <vs-td> {{ product.name }}</vs-td>
          <vs-td> {{ product.game.name }}</vs-td>
          <vs-td>
            {{ product.createdAt | formatTimestamp }}
          </vs-td>
        </vs-tr>
      </template>
      <template #footer v-if="products">
        <vs-pagination v-model="page" :length="products.totalPages" />
      </template>
    </vs-table>
  </div>
</template>

<script>
import { getAllProducts } from "@/services/ApiService";

export default {
  name: "ProductTable",
  props: {
    products: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    page: 1,
    max: 10,
    selected: null,
  }),
  methods: {
    async getGames() {
      const loading = this.$vs.loading({
        target: this.$refs.table,
        background: "rgba(0, 0, 0, 0.6)",
        color: "primary",
        type: "points",
      });

      const { data } = await getAllProducts(this.page, false, true);
      this.products = data;

      loading.close();
    },
    checkActive(plan) {
      return plan.ready;
    },
  },
  watch: {
    page() {
      this.getGames();
    },
    onlyApprovedOnes() {
      getAllProducts(this.page, false, true).then((data) => {
        this.products = data;
      });
    },
    readySwitch() {
      getAllProducts(this.page, false, true).then((data) => {
        this.products = data;
      });
    },
    selected: {
      handler() {
        const id = this.selected._id;
        this.$router.push({
          name: "updateProduct",
          params: { id },
        });
      },
      deep: true,
    },
  },
};
</script>

<style scoped>

</style>
